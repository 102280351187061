<template>
  <div>
    <div class="page-header panel">
      <h3 class="title">
        Хүслийн хүргэлт
      </h3>
      <div class="action-section">
        <router-link to="/desired-delivery-add">
          <el-button type="success" size="mini" icon="el-icon-plus"
            >Нэмэх</el-button
          >
        </router-link>
      </div>
    </div>
    <el-row :gutter="0">
      <el-col :span="24" :offset="0">
        <div class="panel payments-container">
          <div align="right">
            <el-input
              style="width: 200px"
              prefix-icon="el-icon-search"
              v-model="search"
              size="mini"
              placeholder="Хайх үйлчилгээний нэр"
            />
          </div>
          <el-table :data="bags" size="mini">
            <el-table-column
              prop="name_mon"
              label="Монгол нэр"
            ></el-table-column>
            <el-table-column
              prop="name_eng"
              label="Англи нэр"
            ></el-table-column>
            <el-table-column prop="price" label="Үнэ"></el-table-column>
            <el-table-column
              prop="created_at"
              label="Үүссэн огноо"
            ></el-table-column>
            <el-table-column label="Үйлдэл" width="200px">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-edit"
                  @click="getBagData(scope.row)"
                  round
                ></el-button>
                <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-delete"
                  @click="deleteBag(scope.row)"
                  round
                ></el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <el-dialog
      :title="'Хүслийн жагсаалтын нэр : ' + bagEditData.name_mon + ' '"
      :visible.sync="dialogVisible"
      @close="closeProgress()"
      @closed="closeProgress()"
      width="40%"
      top="50px"
      class="process-dialog"
    >
      <div class="panel">
        <el-form
          :model="bagEditData"
          ref="bagEditData"
          label-width="220px"
          class="demo-ruleForm"
        >
          <el-form-item label="Монгол нэр">
            <el-input
              style="width: 250px"
              size="mini"
              v-model="bagEditData.name_mon"
              placeholder="Үйлчилгээний нэр оруулна уу"
            ></el-input>
          </el-form-item>
          <el-form-item label="Англи нэр">
            <el-input
              style="width: 250px"
              size="mini"
              v-model="bagEditData.name_eng"
              placeholder="Үйлчилгээний англи нэр оруулна уу"
            ></el-input>
          </el-form-item>
          <el-form-item label="Үнэ">
            <el-input
              style="width: 250px"
              size="mini"
              v-model="bagEditData.price"
            ></el-input>
          </el-form-item>
          <el-form-item label="Үүссэн огноо">
            <el-input
              style="width: 250px"
              size="mini"
              v-model="bagEditData.created_at"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="save()">Засварлах</el-button>
            <el-button
              :to="{ path: '/desired-delivery' }"
              @click="closeProgress()"
              >Буцах</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import service from "@/helpers/services.js";
export default {
  name: "desiredDelivery",
  mounted() {
    this.getAllBags();
  },
  data() {
    return {
      dialogVisible: false,
      search: "",
      bags: [],
      bagEditData: {},
      sendEdit: false,
      properties: [],
      isProperty: false
    };
  },
  methods: {
    getBagData(data) {
      service.getOneBag(data._id).then(response => {
        if (response.data.status === "success") {
          this.bagEditData = response.data.data;
          this.dialogVisible = true;
        }
      });
    },
    closeProgress() {
      this.bagEditData = {};
      this.dialogVisible = false;
    },
    getAllBags() {
      this.openFullScreenLoader(true);
      service.getAllBags().then(res => {
        if (res.data.status === "success") {
          this.bags = res.data.data.hits;
          this.openFullScreenLoader(false);
        } else {
          this.openFullScreenLoader(false);
        }
      });
    },
    openFullScreenLoader(status) {
      if (status) {
        this.loading = this.$loading({
          lock: true,
          text: "татаж авч байна...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
      } else {
        this.loading.close();
      }
    },
    save() {
      this.$refs.bagEditData.validate(valid => {
        const body = {
          name_mon: this.bagEditData.name_mon,
          name_eng: this.bagEditData.name_eng,
          price: parseFloat(this.bagEditData.price)
        };
        if (valid) {
          service.updateBag(this.bagEditData._id, body).then(response => {
            if (response.status === "success") {
              this.$notify({
                title: "Амжилттай",
                message: response.message,
                type: "success"
              });
              this.dialogVisible = false;
              this.getAllBags();
            } else if (response.status === "unsuccess") {
              this.$notify({
                title: "Амжилтгүй",
                message: response.message,
                type: "warning"
              });
              this.dialogVisible = false;
            }
          });
        } else {
          this.$notify({
            title: "Анхааруулга",
            message: "Зарим талбарыг бөглөөгүй байна.",
            type: "warning"
          });
          this.dialogVisible = false;
          return false;
        }
      });
    },
    deleteBag(data) {
      this.$confirm(
        "Энэ хүслийн жагсаалт устгахдаа итгэлтэй байна уу?",
        "Анхаар",
        {
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          type: "warning"
        }
      )
        .then(() => {
          service.deleteBag(data._id).then(res => {
            if (res.data.data.status === "updated") {
              this.$notify({
                title: "Амжилттай",
                message: "Хүслийн жагсаалт устгагдлаа",
                type: "success"
              });
              this.getAllBags();
            } else {
              this.$notify({
                title: "Амжилтгүй",
                message: `Хүслийн жагсаалт устгахад алдаа гарлаа  ${res.message}`,
                type: "warning"
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Буцлаа"
          });
        });
    }
  }
};
</script>
